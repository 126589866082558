// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-oer-provider-js": () => import("./../src/pages/oer_provider.js" /* webpackChunkName: "component---src-pages-oer-provider-js" */),
  "component---src-pages-policy-js": () => import("./../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-analytics-js": () => import("./../src/pages/products/analytics.js" /* webpackChunkName: "component---src-pages-products-analytics-js" */),
  "component---src-pages-products-blind-js": () => import("./../src/pages/products/blind.js" /* webpackChunkName: "component---src-pages-products-blind-js" */),
  "component---src-pages-products-code-js": () => import("./../src/pages/products/code.js" /* webpackChunkName: "component---src-pages-products-code-js" */),
  "component---src-pages-products-connect-js": () => import("./../src/pages/products/connect.js" /* webpackChunkName: "component---src-pages-products-connect-js" */),
  "component---src-pages-products-discovery-js": () => import("./../src/pages/products/discovery.js" /* webpackChunkName: "component---src-pages-products-discovery-js" */),
  "component---src-pages-products-feed-js": () => import("./../src/pages/products/feed.js" /* webpackChunkName: "component---src-pages-products-feed-js" */),
  "component---src-pages-products-moodle-js": () => import("./../src/pages/products/moodle.js" /* webpackChunkName: "component---src-pages-products-moodle-js" */),
  "component---src-pages-products-recommend-js": () => import("./../src/pages/products/recommend.js" /* webpackChunkName: "component---src-pages-products-recommend-js" */),
  "component---src-pages-products-translate-js": () => import("./../src/pages/products/translate.js" /* webpackChunkName: "component---src-pages-products-translate-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transparency-js": () => import("./../src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */)
}

